import { useStore } from '../../stores/store'
import { Link, useLocation } from 'react-router-dom'

export function NavbarLink({ href, text }: { href: string; text: string }) {
	const location = useLocation()
	const isActive =
		location.pathname === href || location.pathname.startsWith(`${href}/`)
	const setDropdownOpen = useStore((state) => state.setDropdownOpen)

	const handleClick = () => {
		setDropdownOpen(false)
	}

	const fhref =
		href === '/blog'
			? '/blog/about-me'
			: href === '/projects'
			? '/projects/featured-work'
			: href

	return (
		<div>
			<Link to={fhref} onClick={handleClick}>
				<h3
					className={`
					${isActive ? 'text-primary-hover' : 'text-primary'}
					text-xl uppercase
					hover:text-primary-hover hover:cursor-pointer 
					transition-all duration-300 ease-in-out
				`}
				>
					{text}
				</h3>
			</Link>
		</div>
	)
}
