import { useStore } from '../../stores/store'
import { DropdownLink } from './dropdownLink'

export function DropdownContent() {
	const dropdownOpen = useStore((state) => state.dropdownOpen)

	return (
		<div className='z-10 absolute top-[78px] left-0 w-full overflow-hidden'>
			<div
				className={`
					bg-background w-full transition-all duration-300 ease-in-out
					${
						dropdownOpen
							? 'translate-y-0 max-h-screen opacity-100'
							: '-translate-y-full max-h-0 opacity-0'
					}
        		`}
			>
				<div className='flex flex-col border-b-2 border-secondary'>
					<DropdownLink href='/' text='Home' />
					<DropdownLink href='/blog' text='Blog' />
					<DropdownLink href='/projects' text='Projects' />
					<DropdownLink href='/contact' text='Contact' />
				</div>
			</div>
		</div>
	)
}
