import { Link, Outlet, useLocation } from 'react-router-dom'
import slugs from '../static/jsons/projectsSlugs.json'

export default function Projects() {
	const location = useLocation()

	return (
		/* Projects Page Container */
		<div className='w-full h-full p-6 flex flex-col items-center'>
			<div className='max-w-[1200px] w-full flex flex-col md:flex-row gap-10'>
				{/* Side Content (Projects Archive Links) */}
				<div className='w-full md:w-1/3 flex flex-col gap-6'>
					<h1 className='text-2xl text-primary font-bold'>
						Projects Archive
					</h1>
					<div className='flex flex-col border-t border-secondary'>
						{slugs.map((slug) => (
							<Link to={`/projects/${slug}`} key={slug}>
								<p
									className={`
									${
										location.pathname ===
										`/projects/${slug}`
											? 'text-primary-hover'
											: 'text-primary'
									}
									text-base uppercase 
									py-2 border-b border-secondary 
									hover:text-primary-hover hover:cursor-pointer 
									transition-all duration-100 ease-in-out
								`}
								>
									{slug.replaceAll('-', ' ')}
								</p>
							</Link>
						))}
					</div>
				</div>

				{/* Main Content (Projects Archive Page) */}
				<div className='w-full md:w-2/3 flex flex-col gap-6'>
					<Outlet />
				</div>
			</div>
		</div>
	)
}
