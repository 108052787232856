import { create } from 'zustand'

export interface AppState {
    dropdownOpen: boolean
    activeGalleryIndex: string
    totalGalleryItems: string
    setDropdownOpen: (open: boolean) => void
    setActiveGalleryIndex: (index: string) => void
}

export const useStore = create<AppState>()((set) => ({
    dropdownOpen: false,
    activeGalleryIndex: '05',
    totalGalleryItems: '09',
    setDropdownOpen: (open: boolean) => set(({ dropdownOpen: open })),
    setActiveGalleryIndex: (index: string) => set(({ activeGalleryIndex: index }))
}))