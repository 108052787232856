import { useStore } from '../../stores/store'
import { Link } from 'react-router-dom'

export function NavbarLogo() {
	const setDropdownOpen = useStore((state) => state.setDropdownOpen)

	const handleClick = () => {
		setDropdownOpen(false)
	}

	return (
		<div>
			<Link to={'/'} onClick={handleClick}>
				<h3 className='text-xl text-primary font-bold uppercase hover:cursor-pointer'>
					Elliot Ha
				</h3>
			</Link>
		</div>
	)
}
