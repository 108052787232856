import links from '../static/jsons/links.json'

export default function Contact() {
	return (
		<div className='w-full h-full p-6 flex flex-col items-center'>
			<div className='max-w-[1200px] w-full flex flex-col md:flex-row gap-10'>
				{/* Side Content (Projects Archive Links) */}
				<div className='w-full md:w-1/3 flex flex-col gap-6'>
					<h1 className='text-2xl text-primary font-bold'>
						Links Archive
					</h1>
					<div className='flex flex-col border-t border-secondary'>
						{links.map((link, index) => (
							<a
								key={index}
								href={link.href}
								target='_blank'
								rel='noreferrer'
								className={`
									text-base text-primary uppercase 
									py-2 border-b border-secondary 
									hover:text-primary-hover hover:cursor-pointer 
									transition-all duration-100 ease-in-out
								`}
							>
								{link.text}
							</a>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}
