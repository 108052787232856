import { useEffect } from 'react'
import { useStore } from './stores/store'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Navbar from './components/navbar/navbar'
import Footer from './components/footer/footer'
import MarkdownPage from './components/markdownPage'

import Home from './pages/home'
import Blog from './pages/blog'
import Projects from './pages/projects'
import Contact from './pages/contact'
import NotFound from './pages/notFound'

export default function App() {
	const dropdownOpen = useStore((state) => state.dropdownOpen)
	const setDropdownOpen = useStore((state) => state.setDropdownOpen)

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth >= 768) {
				setDropdownOpen(false)
			}
		}
		window.addEventListener('resize', handleResize)
		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	}, [setDropdownOpen])

	return (
		<Router>
			<div className='flex flex-col w-screen h-screen overflow-x-hidden'>
				<Navbar />
				<div
					className={`
						flex-1 flex flex-col
						${
							dropdownOpen
								? 'opacity-40 bg-background-dark pointer-events-none overflow-y-hidden'
								: 'opacity-100 bg-background pointer-events-auto overflow-y-auto'
						}
						transition-all duration-200 ease-in-out
				`}
				>
					<div className='flex-1'>
						<Routes>
							<Route path='/' element={<Home />} />
							<Route path='/blog' element={<Blog />}>
								<Route
									path=':slug'
									element={<MarkdownPage dir='blog' />}
								/>
							</Route>
							<Route path='/projects' element={<Projects />}>
								<Route
									path=':slug'
									element={<MarkdownPage dir='projects' />}
								/>
							</Route>
							<Route path='/contact' element={<Contact />} />
							<Route path='*' element={<NotFound />} />
						</Routes>
					</div>
					<Footer />
				</div>
			</div>
		</Router>
	)
}
