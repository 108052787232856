export default function Footer() {
	return (
		<div className='p-6 flex flex-row justify-between'>
			<p className='text-base text-primary uppercase'>
				A digital design archive
			</p>
			<p className='text-base text-primary uppercase'>© 2024</p>
		</div>
	)
}
