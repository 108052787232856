import { Dropdown } from '../dropdown/dropdown'
import { NavbarLink } from './navbarLink'
import { NavbarLogo } from './navbarLogo'

export default function Navbar() {
	return (
		<div className='p-6 flex flex-row justify-between items-center border-b-2 border-secondary'>
			<NavbarLogo />
			<div>
				<div className='w-[18px] h-7 md:hidden'>
					<Dropdown />
				</div>
				<div className='hidden md:flex flex-row gap-10'>
					<NavbarLink href='/' text='Home' />
					<NavbarLink href='/blog' text='Blog' />
					<NavbarLink href='/projects' text='Projects' />
					<NavbarLink href='/contact' text='Contact' />
				</div>
			</div>
		</div>
	)
}
