import pic1 from '../static/images/pic1.png'
import pic2 from '../static/images/pic2.png'
import pic3 from '../static/images/pic3.png'
import pic4 from '../static/images/pic4.png'
import pic5 from '../static/images/pic5.png'
import pic6 from '../static/images/pic6.png'
import pic7 from '../static/images/pic7.png'
import pic8 from '../static/images/pic8.png'
import pic9 from '../static/images/pic9.png'

import { useStore } from '../stores/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

const GalleryCounter = () => {
	const activeGalleryIndex = useStore((state) => state.activeGalleryIndex)
	const totalGalleryItems = useStore((state) => state.totalGalleryItems)

	return (
		<div className='w-full flex flex-row justify-between'>
			<div className='w-1/3 flex flex-row justify-start'>
				<h2 className='text-2xl text-primary font-bold'>
					{activeGalleryIndex}
				</h2>
			</div>
			<div className='w-1/3 flex flex-row justify-center'>
				<h2 className='text-2xl text-primary font-bold'>/</h2>
			</div>
			<div className='w-1/3 flex flex-row justify-end'>
				<h2 className='text-2xl text-primary font-bold'>
					{totalGalleryItems}
				</h2>
			</div>
		</div>
	)
}

const GalleryItem = ({
	src,
	alt,
	info,
	title,
	body,
}: {
	src: string
	alt: string
	info: string
	title: string
	body: string
}) => {
	const activeGalleryIndex = useStore((state) => state.activeGalleryIndex)
	const setActiveGalleryIndex = useStore(
		(state) => state.setActiveGalleryIndex
	)
	const isActive = activeGalleryIndex === alt

	const handleMouseEnter = () => {
		setActiveGalleryIndex(alt)
	}

	return (
		<div
			className={`
			flex-col gap-4
			${isActive ? 'flex' : 'hidden'}
			lg:flex
		`}
		>
			{/* Image */}
			<div
				className={`
				h-[480px] overflow-hidden
				${isActive ? 'w-[340px] opacity-100' : 'w-[70px] opacity-40'} 
				transition-all duration-500 ease-in-out
			`}
				onMouseEnter={handleMouseEnter}
			>
				<img src={src} alt={alt} className='object-cover h-full' />
			</div>

			{/* Text */}
			<div
				className={`
				flex flex-col gap-2 overflow-hidden text-nowrap
				${isActive ? 'w-[340px] opacity-100' : 'w-[70px] opacity-0'}	
				transition-all duration-500 ease-in-out
			`}
			>
				<p className='text-sm text-primary uppercase'>{info}</p>
				<h2 className='text-2xl text-primary font-bold uppercase'>
					{title}
				</h2>
				<p className='text-base text-primary'>
					{body.split('\\n').map((line, index) => (
						<span key={index}>
							{line}
							<br />
						</span>
					))}
				</p>
			</div>
		</div>
	)
}

const GalleryItems = () => {
	return (
		<div className='flex flex-row gap-1'>
			<GalleryItem
				src={pic1}
				alt='01'
				info='New York, 2022'
				title='NYU Visit'
				body='Visiting New York for the first time during my \n sophomore year spring break'
			/>
			<GalleryItem
				src={pic2}
				alt='02'
				info='Los Angeles, 2023'
				title='Apocalypse Music Fest'
				body="Shots of Apocalypse 2023 at Queen Mary's \n Waterfront"
			/>
			<GalleryItem
				src={pic3}
				alt='03'
				info='Los Angeles, 2021'
				title='UCLA Visit'
				body='Polaroid memories from visiting my friends at \n UCLA for fall break'
			/>
			<GalleryItem
				src={pic4}
				alt='04'
				info='Los Angeles, 2022'
				title="Head in the Clouds '22"
				body='Keshi performing at 88 Stage at HITC Los \n Angeles 2022'
			/>
			<GalleryItem
				src={pic5}
				alt='05'
				info='New York, 2023'
				title='Elliot Hyunwoo Ha'
				body='Duke University Alum c/o 2024'
			/>
			<GalleryItem
				src={pic6}
				alt='06'
				info='Los Angeles, 2021'
				title="Head in the Clouds '21"
				body='CL, DPR Ian, and DPR Live performing \n "No Blueberries" at HITC LA 2021'
			/>
			<GalleryItem
				src={pic7}
				alt='07'
				info='Las Vegas, 2024'
				title='Electric Daisy Carnival'
				body="Dom Dolla playing at Circuit Grounds at \n EDC Las Vegas '24"
			/>
			<GalleryItem
				src={pic8}
				alt='08'
				info='New York, 2023'
				title='Koreatown NYC'
				body="Ending the best summer of my life back in \n Manhattan's Koreatown"
			/>
			<GalleryItem
				src={pic9}
				alt='09'
				info='New York, 2023'
				title='Electric Zoo Festival'
				body="The walk back across the bridge after the \n disaster that was EZoo '23"
			/>
		</div>
	)
}

export default function Home() {
	const activeGalleryIndex = useStore((state) => state.activeGalleryIndex)
	const setActiveGalleryIndex = useStore(
		(state) => state.setActiveGalleryIndex
	)
	const totalGalleryItems = useStore((state) => state.totalGalleryItems)
	const totalItems = parseInt(totalGalleryItems, 10)

	const handleClickPrev = () => {
		let currentIndex = parseInt(activeGalleryIndex, 10)
		let prevIndex = currentIndex - 1
		if (prevIndex < 1) {
			prevIndex = totalItems
		}
		setActiveGalleryIndex(prevIndex.toString().padStart(2, '0'))
	}

	const handleClickNext = () => {
		let currentIndex = parseInt(activeGalleryIndex, 10)
		let nextIndex = currentIndex + 1
		if (nextIndex > totalItems) {
			nextIndex = 1
		}
		setActiveGalleryIndex(nextIndex.toString().padStart(2, '0'))
	}

	return (
		<div className='w-full h-full p-6 flex flex-row gap-2 items-center justify-center'>
			<div
				className={`
					flex lg:hidden hover:cursor-pointer
				`}
				onClick={handleClickPrev}
			>
				<FontAwesomeIcon
					icon={faChevronLeft}
					className='text-2xl text-primary scale-100 hover:scale-110 hover:text-primary-hover transition-all duration-300 ease-in-out'
				/>
			</div>
			<div className='flex flex-col gap-4'>
				{/* Counter */}
				<GalleryCounter />

				{/* Gallery Items */}
				<GalleryItems />
			</div>
			<div
				className={`
					flex lg:hidden hover:cursor-pointer
				`}
				onClick={handleClickNext}
			>
				<FontAwesomeIcon
					icon={faChevronRight}
					className='text-2xl text-primary scale-100 hover:scale-110 hover:text-primary-hover transition-all duration-300 ease-in-out'
				/>
			</div>
		</div>
	)
}
