import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useStore } from '../../stores/store'

export function DropdownButton() {
	const dropdownOpen = useStore((state) => state.dropdownOpen)
	const setDropdownOpen = useStore((state) => state.setDropdownOpen)

	return (
		<div className='w-full h-full relative'>
			<FontAwesomeIcon
				icon={faBars}
				className={`
					absolute transition-all duration-300 ease-in-out 
					top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
					${dropdownOpen ? 'opacity-0 scale-50 z-0' : 'opacity-100 scale-100 z-10'}
					text-xl text-primary hover:text-primary-hover hover:cursor-pointer
				`}
				onClick={() => setDropdownOpen(true)}
			/>
			<FontAwesomeIcon
				icon={faXmark}
				className={`
					absolute transition-all duration-100 ease-in-out 
					top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
					${dropdownOpen ? 'opacity-100 scale-100 z-10' : 'opacity-0 scale-50 z-0'}
					text-2xl text-primary hover:text-primary-hover hover:cursor-pointer
				`}
				onClick={() => setDropdownOpen(false)}
			/>
		</div>
	)
}
