import { DropdownButton } from './dropdownButton'
import { DropdownContent } from './dropdownContent'

export function Dropdown() {
	return (
		<>
			<DropdownButton />
			<DropdownContent />
		</>
	)
}
