import { useStore } from '../../stores/store'
import { Link, useLocation } from 'react-router-dom'

export function DropdownLink({ href, text }: { href: string; text: string }) {
	const location = useLocation()
	const isActive =
		location.pathname === href || location.pathname.startsWith(`${href}/`)
	const setDropdownOpen = useStore((state) => state.setDropdownOpen)

	const handleClick = () => {
		setDropdownOpen(false)
	}

	const fhref =
		href === '/blog'
			? '/blog/about-me'
			: href === '/projects'
			? '/projects/featured-work'
			: href

	return (
		<div className='border-b border-secondary bg-transparent hover:bg-secondary'>
			<Link to={fhref} onClick={handleClick}>
				<h3
					className={`
					${isActive ? 'text-primary-hover' : 'text-primary'}
					px-6 py-2 uppercase text-xl 
					hover:text-primary-hover hover:cursor-pointer	
				`}
				>
					{text}
				</h3>
			</Link>
		</div>
	)
}
