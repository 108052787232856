import { Components } from 'react-markdown'

export const styledComponents: Components = {
	h1: ({ children }) => (
		<h1 className='text-2xl text-primary font-bold'>{children}</h1>
	),
	h2: ({ children }) => (
		<h2 className='text-xl text-primary font-bold'>{children}</h2>
	),
	h3: ({ children }) => (
		<h3 className='text-lg text-primary font-bold'>{children}</h3>
	),
	p: ({ children }) => <p className='text-base text-primary'>{children}</p>,
	a: ({ children, href }) => (
		<a
			href={href}
			className='text-base text-primary underline hover:text-primary-hover hover:cursor-pointer transition-all duration-300 ease-in-out'
		>
			{children}
		</a>
	),
	ul: ({ children }) => (
		<ul className='list-disc list-inside text-primary'>{children}</ul>
	),
	ol: ({ children }) => (
		<ol className='list-decimal list-inside text-primary'>{children}</ol>
	),
	hr: () => <hr className='border-t border-secondary my-2' />,
}
