import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'

import { styledComponents } from '../markdownStyles'

export default function MarkdownPage({ dir }: { dir: string }) {
	const { slug } = useParams()
	const [content, setContent] = useState('')
	const [error, setError] = useState(false)

	useEffect(() => {
		import(`../static/markdown/${dir}/${slug}.md`)
			.then((file) => fetch(file.default))
			.then((response) => {
				if (!response.ok) {
					throw new Error('Markdown file not found')
				}
				return response.text()
			})
			.then((text) => {
				setContent(text)
				setError(false)
			})
			.catch(() => {
				setError(true)
			})
	}, [dir, slug])

	if (error) {
		return (
			<div>
				<h1 className='text-primary'>404. Page not found.</h1>
			</div>
		)
	}

	return (
		<div className='flex flex-col gap-4 overflow-x-hidden'>
			<ReactMarkdown
				components={styledComponents}
				remarkPlugins={[remarkBreaks]}
			>
				{content}
			</ReactMarkdown>
		</div>
	)
}
